import React from "react";

const Shopee = () => (
  <div>
    <span>
      <h4>🪧 佈告欄：</h4>
    </span>
    <div>
      <p>
        <b> ❥ 注意：限制貼上網域為 https://shp.ee/ 的連結</b>
      </p>
      <p>
        <b> ❥ 每日 00:00 將會把資料清除</b>
      </p>
      <p>
        <b>❥ 平台可能需要透過手機 App 開啟才能幫助他人成功。</b>
      </p>
      <p>
        <b>ps. 記得需等待 APP 完整跳轉成功哦～</b>
      </p>
    </div>
  </div>
);

const Momo = () => (
  <div>
    <span>
      <h4>🪧 佈告欄：</h4>
    </span>
    <div>
      <p>
        <b> ❥ 注意：限制貼上網域為 https://www.momoshop.com.tw/ 的連結</b>
      </p>
      <p>
        <b> ❥ 每日 00:00 將會把資料清除</b>
      </p>
      <p>
        <b>❥ 平台可能需要透過手機 App 開啟才能幫助他人成功。</b>
      </p>
      <p>
        <b>ps. 記得需等待 APP 完整跳轉成功哦～</b>
      </p>
    </div>
  </div>
);

export { Shopee, Momo };
