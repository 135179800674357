import React, { useState, useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import MarqueeAnimation from "./MarqueeAnimation";

const luckycat = `
° ∧＿∧      <br>
（｡･ω･｡)つ━━☆・*。<br>
⊂　　 ノ 　　　・゜+.<br>
..しーＪ　　　°。+ *´¨)<br>
　　　       　　.· ´¸.·*´¨) ¸.·*¨)<br>
　　　　　　　     　(¸.·´ (¸.·’*`;

const DonateModal = ({ setShowModal }) => {
  const [playAnimation, setPlayAnimation] = useState(false);

  // const triggerBarrageEffect = () => {
  //   const barrageContainer = document.createElement("div");
  //   barrageContainer.className = "barrage-container";

  //   const messageElement = document.createElement("div");
  //   messageElement.className = "barrage-message";
  //   messageElement.innerHTML = `你已被好運貓貓造訪 ～～<br>` + luckycat;

  //   barrageContainer.appendChild(messageElement);
  //   document.body.appendChild(barrageContainer);

  //   // Remove the barrage after the animation completes
  //   setTimeout(() => {
  //     document.body.removeChild(barrageContainer);
  //   }, 3000); // Adjust this timing to match your animation duration
  // };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={setShowModal}>
          &times;
        </span>

        <h3>若本平台有幫助到您的話</h3>
        <h3>可以考慮贊助我一杯咖啡的費用來維護伺服器 ☕️</h3>
        <span>請掃描以下 QR code 來進行贊助：</span>
        <div className="qr-code-container">
          <div className="qr-code-item">
            <img src="/JKOPay.jpg" alt="JKOPay" className="qr-code" />
            <p>
              <b>街口支付</b>
            </p>
          </div>

          {/* <div className="qr-code-item">
            <img
              src="./your-qr-code2.png"
              alt="QR Code 2"
              className="qr-code"
            />
            <p>支付方式 2</p>
          </div> */}
        </div>
        <h3>感謝您共同維護伺服器！</h3>
      </div>
    </div>
  );
};

export default DonateModal;
