import React, { useState } from "react";
import Activity from "./Activity";
import { Shopee, Momo } from "./Bulletin";

function TabContent({ brand, activities, isActive }) {
  const [activeActivity, setActiveActivity] = useState(activities[0]);
  return isActive ? (
    <div>
      <div className="subtabs">
        {activities.map((activity) => (
          <button
            key={activity}
            className={`subtablinks ${
              activeActivity === activity ? "active" : ""
            }`}
            onClick={() => setActiveActivity(activity)}
          >
            {activity}
          </button>
        ))}
      </div>
      <div className="bulletin-board">
        {activeActivity === "蝦蝦果園澆水" && <Shopee />}
        {activeActivity === "每日分享－家電館活動" && <Shopee />}
        {activeActivity === "每日分享-蝦皮3C" && <Shopee />}
        {activeActivity === "其他-檔期活動" && <Shopee />}
        {activeActivity === "Mo幣簽到分享任務" && <Momo />}
        {activeActivity === "轉盤分享" && <Momo />}
      </div>
      {activities.map((activity) => (
        <Activity
          key={activity}
          brand={brand}
          activity={activity}
          isActive={activity === activeActivity}
        />
      ))}
    </div>
  ) : null;
}

export default TabContent;
