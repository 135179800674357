import React, { useState } from "react";
import DonateModal from "./DonateModal";
import MarqueeAnimation from "./MarqueeAnimation";

function Footer() {
  const [showModal, setShowModal] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  // const handleOpenModal = () => {
  // setShowModal(true);
  // setTimeout(() => setShowAnimation(true), 100); // 略微延遲以確保 Modal 已關閉
  // };

  // const handleCloseModal = () => {
  // setShowModal(false);
  // setTimeout(() => setShowAnimation(false));
  // };
  const handleOpenModal = () => {
    setShowModal(true);
    setShowAnimation(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAnimationComplete = () => {
    setShowAnimation(false);
  };
  return (
    <div className="footer">
      <div className="footer-main">
        <div className="footer-left">
          <span>🪄 可將本網頁加入至手機桌面，方便分享你的連結哦～</span>
          <div className="footer-button-container">
            <button className="donate-button" onClick={handleOpenModal}>
              我要贊助 ♪
            </button>
          </div>
        </div>
        <div className="footer-right">
          <div className="footer-logo">
            <img
              src="./LogoLinkHelper.png"
              alt="Brand Logo"
              className="brand-logo"
            />
            LinksHelper
          </div>
          <div className="footer-email-text">
            意見回饋、Bug 回報:
            <span aria-label="email" role="img">
              <a
                href="mailto:yunssy.o3o@gmail.com"
                style={{ textDecoration: "none" }}
              >
                📧
              </a>
            </span>
          </div>
        </div>
      </div>
      <div className="copyright">Copyright © 2024 LinksHelper.</div>
      {showModal && <DonateModal setShowModal={handleCloseModal} />}
      {showAnimation && (
        <MarqueeAnimation
          isModalOpen={showModal}
          onAnimationComplete={handleAnimationComplete}
        />
      )}
    </div>
  );
}

export default Footer;
