import React, { useState, useEffect } from "react";
import axios from "axios";

// const host = process.env.REACT_APP_API_HOST || '13.208.192.37';
// const port = process.env.REACT_APP_API_PORT || 3001;
const baseurl = "/api";

function Activity({ brand, activity, isActive }) {
  const [links, setLinks] = useState([
    { index: 1, url: "", clicked: 0, error: false },
  ]);
  const [linksLoaded, setLinksLoaded] = useState(false);
  const [canPaste, setCanPaste] = useState(true);
  const [initialLink, setInitialLink] = useState(null);

  useEffect(() => {
    if (isActive) {
      loadLinks();
    } else {
      resetState();
    }
    // eslint-disable-next-line
  }, [isActive, brand, activity]);

  const loadLinks = () => {
    axios
      .get(`${baseurl}/load-links`, { params: { brand, activity } })
      .then((response) => {
        const data = response.data;
        const loadedLinks = Object.keys(data).map((key) => ({
          index: parseInt(key),
          url: data[key].url,
          clicked: data[key].clicked,
          error: false,
        }));
        setLinks(
          loadedLinks.length > 0
            ? loadedLinks
            : [{ index: 1, url: "", clicked: 0, error: false }]
        );
        setLinksLoaded(true);
        updateCanPasteAndInitialLink(loadedLinks);
      })
      .catch((error) => {
        console.error("載入連結時發生錯誤:", error);
      });
  };

  const resetState = () => {
    setLinks([{ index: 1, url: "", clicked: 0, error: false }]);
    setLinksLoaded(false);
    setCanPaste(true);
    setInitialLink(null);
  };

  useEffect(() => {
    if (linksLoaded) {
      const lastLink = links[links.length - 1];
      if (lastLink && lastLink.url.trim() !== "") {
        addLinkField();
      }
      setLinksLoaded(false);
    }
    // eslint-disable-next-line
  }, [links, linksLoaded]);

  const updateCanPasteAndInitialLink = (links) => {
    const unclickedLink = links.find(
      (link) => link.clicked === 0 && link.url !== ""
    );
    if (unclickedLink) {
      setCanPaste(false);
      setInitialLink(unclickedLink);
    } else {
      setCanPaste(true);
      setInitialLink(null);
    }
  };

  const addLinkField = () => {
    const newIndex = Math.max(...links.map((link) => link.index)) + 1;
    setLinks([
      ...links,
      { index: newIndex, url: "", clicked: 0, error: false },
    ]);
  };

  const handleLinkClick = (index) => {
    const link = links.find((link) => link.index === index);
    if (!link || !isValidURL(link.url)) {
      return;
    }

    axios
      .post(`${baseurl}/update-link`, {
        brand,
        activity,
        index,
        url: link.url,
      })
      .then(() => {
        loadLinks();
      })
      .catch((error) => {
        console.error("更新連結時發生錯誤:", error);
      });
  };

  const handleSaveLink = (index, url) => {
    if (!isValidURL(url) || !canPaste) {
      return;
    }

    axios
      .post(`${baseurl}/save-link`, {
        brand,
        activity,
        data: { [index]: url },
      })
      .then(() => {
        loadLinks();
      })
      .catch((error) => {
        console.error("儲存連結時發生錯誤:", error);
      });
  };

  const handleInputChange = (index, url) => {
    const cleanedUrl = extractValidURL(url);
    setLinks(
      links.map((link) =>
        link.index === index
          ? { ...link, url: cleanedUrl, error: !isValidURL(cleanedUrl) }
          : link
      )
    );
  };

  const handlePaste = (index) => {
    if (!canPaste) return;

    navigator.clipboard
      .readText()
      .then((text) => {
        const cleanedUrl = extractValidURL(text);
        handleInputChange(index, cleanedUrl);
      })
      .catch((err) => {
        console.error("無法讀取剪貼簿內容: ", err);
      });
  };

  const extractValidURL = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = text.match(urlRegex);
    if (matches) {
      for (let url of matches) {
        if (isValidURL(url)) {
          return url;
        }
      }
    }
    return "";
  };

  const handleKeyPress = (event, index, url) => {
    if (event.key === "Enter") {
      handleSaveLink(index, url);
    }
  };

  const isValidURL = (string) => {
    // eslint-disable-next-line
    const urlRegex = new RegExp(
      `^(https:\/\/(shp\\.ee|www\\.momoshop\\.com\\.tw)\/)`
    );
    const urlMatch = string.match(urlRegex);
    if (brand === "蝦皮") {
      return urlMatch && urlMatch[0] === "https://shp.ee/";
    } else if (brand === "Momo") {
      return urlMatch && urlMatch[0] === "https://www.momoshop.com.tw/";
    } else {
      return false;
    }
  };

  return isActive ? (
    <div className="activity">
      {initialLink && (
        <div>
          {/* eslint-disable-next-line */}
          <div>
            <span className="activity-hintText">
              𝙎𝙩𝙖𝙧𝙩 ଘ(੭ˊ꒳ˋ)੭✧ 請先點擊此連結後，再貼上自己的連結 👉🏻：
            </span>
            <a
              href={initialLink.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick(initialLink.index)}
            >
              {initialLink.url}
            </a>
          </div>
        </div>
      )}
      <div className="input-row">
        <input
          type="url"
          value={links[links.length - 1].url}
          placeholder={canPaste ? "貼上您的分享連結" : "請先點擊上方連結"}
          onChange={(e) =>
            handleInputChange(links[links.length - 1].index, e.target.value)
          }
          onKeyPress={(e) =>
            handleKeyPress(
              e,
              links[links.length - 1].index,
              links[links.length - 1].url
            )
          }
          disabled={!canPaste}
        />
        <img
          src="/paste.svg"
          alt="Paste"
          className="paste-icon"
          onClick={() => handlePaste(links[links.length - 1].index)}
          style={{
            opacity: canPaste ? 1 : 0.5,
            cursor: canPaste ? "pointer" : "default",
          }}
        />
        {links[links.length - 1].url &&
          isValidURL(links[links.length - 1].url) &&
          canPaste && (
            <span
              className="save-icon-wrapper"
              onClick={() =>
                handleSaveLink(
                  links[links.length - 1].index,
                  links[links.length - 1].url
                )
              }
            >
              <img src="/square-ok.svg" alt="Save" className="save-icon" />
            </span>
          )}
      </div>
      {links.slice(0, -1).map((link) => (
        <div key={link.index} className="input-row">
          <span style={{ fontSize: 20 }}>{link.index}. </span>
          <span>{link.url}</span>
        </div>
      ))}
    </div>
  ) : null;
}

export default Activity;
